export const INSIGHTS_CONSTANTS = {
  HOME_PAGE: {
    HOME_PAGE_LOADED: "HOME_PAGE_LOADED",
    HOME_PAGE_SESSION_DURATION: "HOME_PAGE_SESSION_DURATION",
    HOME_BUTTON_GENERATE_VOICE: "HOME_BUTTON_GENERATE_VOICE",
    HOME_SELECT_LANGUAGE: "HOME_SELECT_LANGUAGE",
    HOME_SELECT_VOICE: "HOME_SELECT_VOICE",
    HOME_SELECT_STYLE: "HOME_SELECT_STYLE",
    HOME_SELECT_PITCH: "HOME_SELECT_PITCH",
    HOME_SELECT_SPEED: "HOME_SELECT_SPEED",
    HOME_INPUT_TEXT: "HOME_INPUT_TEXT",
    HOME_BUTTON_DOWNLOAD: "HOME_BUTTON_DOWNLOAD",
  },
  INFORMATION_PAGE: {
    INFORMATION_BUTTON_BUY_ME_A_COFFEE: "INFORMATION_BUTTON_BUY_ME_A_COFFEE",
  },
  ABOUT_PAGE: {
    ABOUT_PAGE_LOADED: "ABOUT_PAGE_LOADED",
    ABOUT_PAGE_SESSION_DURATION: "ABOUT_PAGE_SESSION_DURATION",
  },
}